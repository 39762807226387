import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import * as MUI from '@material-ui/core';

interface Props {
  title: string;
  currency?: string;
  price: number | string;
  discountedPrice?: number | string;
  discountCoupon?: string;
  discountAmount?: string;
  recurringInterval?: string;
  secondaryPrice?: number | string;
  secondaryInterval?: string;
  description: React.ReactNode[];
  buttonText: string;
  buttonIcon: React.ReactNode;
  buttonAction?: () => void;
  buttonHref?: string;
  buttonTo?: string;
  buttonDisabled?: boolean;
  bundleTitle?: React.ReactNode;
  bundleDescription?: React.ReactNode;
  bundleButtonText?: string;
  bundleButtonIcon?: React.ReactNode;
  bundleButtonAction?: () => void;
}

export default function PricingCard(props: Props) {
  return (
    <MUI.Grid item xs={12} sm={6} md={4}>
      <MUI.Card>
        <MUI.CardHeader
          title={props.title}
          titleTypographyProps={{ align: 'center' }}
          subheaderTypographyProps={{ align: 'center' }}
          style={{ backgroundColor: '#eeeeee', marginBottom: '32px' }}
        />

        <MUI.CardContent>
          {props.discountCoupon && props.discountAmount && (
            <MUI.Box display='flex' justifyContent='center' mb='16px'>
              <MUI.Chip
                label={
                  <>
                    On sale! Use <b>{props.discountCoupon}</b> to get {props.discountAmount} off
                  </>
                }
                style={{ backgroundColor: 'rgb(214, 245, 214)' }}
              />
            </MUI.Box>
          )}

          <MUI.Box display='flex' justifyContent='center' alignItems='baseline'>
            {props.discountedPrice && (
              <MUI.Typography
                variant='h5'
                color='primary'
                style={{ textDecoration: 'line-through', marginRight: '16px' }}
              >
                {props.price}
                {props.currency}
              </MUI.Typography>
            )}
            <MUI.Typography variant='h3' color='primary'>
              {props.discountedPrice ? props.discountedPrice : props.price}
              {props.currency}
            </MUI.Typography>
            {props.recurringInterval && (
              <MUI.Typography variant='h6' color='textSecondary'>
                {props.recurringInterval}
              </MUI.Typography>
            )}
          </MUI.Box>

          {props.secondaryPrice && (
            <MUI.Box display='flex' justifyContent='center' alignItems='baseline'>
              <MUI.Typography variant='body1'>
                {`Then ${props.secondaryPrice}${props.currency}`}
              </MUI.Typography>
              {props.recurringInterval && (
                <MUI.Typography variant='body1' color='textSecondary'>
                  {props.secondaryInterval}
                </MUI.Typography>
              )}
            </MUI.Box>
          )}

          {props.price > 0 && (
            <MUI.Box display='flex' justifyContent='center'>
              <MUI.Typography variant='caption' color='textSecondary'>
                (+tax)
              </MUI.Typography>
            </MUI.Box>
          )}

          <ul
            style={{
              margin: 0,
              marginTop: '32px',
              marginBottom: '16px',
              padding: 0,
              listStyle: 'none',
              color: '#888'
            }}
          >
            {props.description.map((line, index) => (
              <MUI.Typography
                component='li'
                variant='body2'
                align='center'
                key={index}
                style={{ lineHeight: 1.2, marginTop: '16px' }}
              >
                {line}
              </MUI.Typography>
            ))}
          </ul>
        </MUI.CardContent>

        <MUI.CardActions>
          {props.buttonTo ? (
            <MUI.Button
              fullWidth
              variant='contained'
              disableElevation
              color='primary'
              startIcon={props.buttonIcon}
              style={{ fontWeight: 'bold' }}
              component={RouterLink}
              to={props.buttonTo}
              disabled={props.buttonDisabled}
            >
              {props.buttonText}
            </MUI.Button>
          ) : (
            <MUI.Button
              fullWidth
              variant='contained'
              disableElevation
              color='primary'
              startIcon={props.buttonIcon}
              style={{ fontWeight: 'bold' }}
              onClick={props.buttonAction}
              href={props.buttonHref}
              disabled={props.buttonDisabled}
            >
              {props.buttonText}
            </MUI.Button>
          )}
        </MUI.CardActions>

        {(props.bundleTitle || props.bundleDescription) && (
          <>
            <MUI.CardContent>
              <MUI.Box textAlign='center' mb={2}>
                <MUI.Typography variant='h6'>{props.bundleTitle}</MUI.Typography>
              </MUI.Box>
              <MUI.Box textAlign='center' color='#888'>
                <MUI.Typography variant='body2'>{props.bundleDescription}</MUI.Typography>
              </MUI.Box>
            </MUI.CardContent>

            <MUI.CardActions>
              <MUI.Button
                fullWidth
                variant='contained'
                disableElevation
                color='primary'
                startIcon={props.bundleButtonIcon}
                style={{ fontWeight: 'bold' }}
                onClick={props.bundleButtonAction}
                disabled={props.buttonDisabled}
              >
                {props.bundleButtonText}
              </MUI.Button>
            </MUI.CardActions>
          </>
        )}
      </MUI.Card>
    </MUI.Grid>
  );
}

interface EducatorDialogProps {
  type: 'educator' | 'institution';
  email: string;
  onClose: () => void;
}

export function EducatorDialog(props: EducatorDialogProps) {
  return (
    <MUI.Dialog open onClose={props.onClose} maxWidth='md' fullWidth>
      <MUI.DialogTitle>
        {props.type === 'educator' ? 'Educator License' : 'Institution License'}
      </MUI.DialogTitle>

      <MUI.DialogContent>
        <MUI.DialogContentText>
          {props.type === 'educator' ? (
            <>
              If you are an educator or a teacher, we can provide you with a full-featured free
              license. <br />
              <br />
              Educator Licenses <b>cannot be used for any kind of commercial work.</b>
              <br />
              <br />
              You will be asked to provide proof that you are currently an educator (Faculty ID,
              training center certificate or similar document)
              <br />
              <br />
              Contact us at {props.email}
            </>
          ) : (
            <>
              If you are an educational institution, we can equip your whole lab with licenses for
              free.
              <br />
              <br />
              Contact us at {props.email}
            </>
          )}
        </MUI.DialogContentText>
      </MUI.DialogContent>

      <MUI.DialogActions>
        <MUI.Button onClick={props.onClose}>Close</MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
}
