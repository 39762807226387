import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import * as MUI from '@material-ui/core';
import CartIcon from '@material-ui/icons/ShoppingCart';
import StudentIcon from '@material-ui/icons/School';
import EducatorIcon from '@material-ui/icons/Person';
import UniversityIcon from '@material-ui/icons/AccountBalance';

import PricingCard, { EducatorDialog } from '../Common/PricingCard';
import Faq from '../Common/Faq';
import SearchResellerDialog from '../Resellers/SearchResellerDialog';
import { NotifyFunction } from '../Common/utils';

interface Props {
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function TransmutrPriceTable(props: Props) {
  const [tab, setTab] = React.useState('pro');
  const [resellerDialogOpen, setResellerDialogOpen] = React.useState(false);
  const [educatorDialogOpen, setEducatorDialogOpen] = React.useState<
    'educator' | 'institution' | undefined
  >(undefined);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
  };

  const handlePayment = (
    paddleProductId: string,
    allowQuantity: boolean,
    discountCode?: string,
    upsell?: {
      upsellProductId: string;
      upsellTitle: string;
      upsellText: string;
      upsellAction: string;
    }
  ) => {
    // @ts-ignore
    Paddle.Checkout.open({
      product: paddleProductId,
      upsell: upsell?.upsellProductId,
      upsellTitle: upsell?.upsellTitle,
      upsellText: upsell?.upsellText,
      upsellAction: upsell?.upsellAction,
      allowQuantity: allowQuantity,
      coupon: discountCode,
      customData: {
        // @ts-ignore
        tolt_referral: window.tolt_referral
      }
    });
  };

  return (
    <MUI.Box>
      <MUI.Box mt={4}>
        <MUI.Tabs value={tab} onChange={handleTabChange} indicatorColor='primary' centered>
          <MUI.Tab label='Professional' value='pro' />
          <MUI.Tab label='Educational' value='edu' />
        </MUI.Tabs>
      </MUI.Box>

      <MUI.Box mt={6} mb={8}>
        {tab === 'pro' && (
          <MUI.Container maxWidth='md'>
            <MUI.Grid container spacing={5} alignItems='flex-start' justifyContent='center'>
              <PricingCard
                title='Artist'
                price={99}
                currency='€'
                recurringInterval='/yr'
                description={[
                  'Fixed-seat license',
                  'Selling files generated by Transmutr Artist on an online store is prohibited'
                ]}
                buttonText='buy'
                buttonIcon={<CartIcon />}
                buttonAction={() =>
                  handlePayment('666363', true, undefined, {
                    upsellProductId: '739362',
                    upsellTitle: 'Skatter + Transmutr bundle',
                    upsellText: 'Buy Skatter and Transmutr together to get 10€ off',
                    upsellAction: 'Buy Skatter+Transmutr'
                  })
                }
                bundleTitle={
                  <>
                    Buy Skatter and Transmutr together to get <b>10€ off</b>!
                  </>
                }
                bundleDescription={
                  <>
                    Transmutr is a great complement to Skatter, the most powerful Scattering
                    Extension for SketchUp. Render huge amounts of vegetation, quickly populate city
                    blocks, create parametric assemblies, crowds, carpets, you name it... All right
                    inside SketchUp!
                    <br />
                    <MUI.Link component={RouterLink} to='/skatter' color='inherit'>
                      Learn More
                    </MUI.Link>
                  </>
                }
                bundleButtonText='buy Skatter + Transmutr'
                bundleButtonIcon={<CartIcon />}
                bundleButtonAction={() => handlePayment('739362', true)}
              />

              <PricingCard
                title='Studio'
                price={299}
                currency='€'
                recurringInterval='/yr'
                description={[
                  'Floating license',
                  'Permission to sell files generated by Transmutr Studio',
                  'Large scale processing with scripting and multiple-files handling',
                  <>
                    Optional Priority Support:{' '}
                    <a href='mailto:transmutr@lindale.io'>contact us for a quote</a>
                  </>
                ]}
                buttonText='buy'
                buttonIcon={<CartIcon />}
                buttonAction={() => handlePayment('555805', true)}
              />
            </MUI.Grid>
          </MUI.Container>
        )}

        {tab === 'edu' && (
          <MUI.Container maxWidth='md'>
            <MUI.Grid container spacing={5} alignItems='flex-start' justify='center'>
              <PricingCard
                title='Student'
                price={5}
                currency='€'
                description={[
                  'Full featured, valid for 1 year',
                  'Fixed-seat license',
                  'Not for commercial use'
                ]}
                buttonText='Apply'
                buttonIcon={<StudentIcon />}
                buttonTo='/students'
              />

              <PricingCard
                title='Educator'
                price='Free'
                description={['Full featured', 'Fixed-seat license', 'Not for commercial use']}
                buttonText='Request'
                buttonIcon={<EducatorIcon />}
                buttonAction={() => setEducatorDialogOpen('educator')}
              />

              <PricingCard
                title='Institutions'
                price='Free'
                description={['Full featured', 'Floating licenses', 'Not for commercial use']}
                buttonText='Request'
                buttonIcon={<UniversityIcon />}
                buttonAction={() => setEducatorDialogOpen('institution')}
              />
            </MUI.Grid>
          </MUI.Container>
        )}
      </MUI.Box>

      <MUI.Box display='flex' justifyContent='center'>
        <MUI.Button variant='outlined' color='primary' onClick={() => setResellerDialogOpen(true)}>
          Find a reseller
        </MUI.Button>
      </MUI.Box>

      <MUI.Box mt={8} mb={8}>
        <MUI.Container maxWidth='md'>
          <Faq
            entries={[
              {
                title: 'What formats can Transmutr import?',
                description: (
                  <>
                    As of today, Transmutr can import <b>SKP, FBX, OBJ, 3DS, DAE, and STL</b>. We
                    plan to add new formats in upcoming releases.
                  </>
                )
              },
              {
                title: 'On how many computers can I install my license?',
                description: (
                  <>
                    - An <b>Artist</b> license can be installed on <b>two computers</b>. For
                    example, a laptop and a desktop workstation.
                    <br />- A <b>Studio</b> license is floating. It can be installed on as many
                    computers as you want, but can only be used simultaneously on the number of
                    seats you purchase.
                    <br />
                    If it has one seat, it can be used on only one computer at a time. The
                    application must be closed before someone else can use it.
                    <br />
                    If it has 5 seats, it can be used on 5 computers at a time, etc.
                    <br />
                    To purchase more than one seat, change the 'quantity' field during the checkout
                    process.
                  </>
                )
              },
              {
                title: 'Can I sell the files generated by Transmutr Artist?',
                description: (
                  <>
                    No, you need a <b>Transmutr Studio license to be allowed to sell the files</b>{' '}
                    generated by Transmutr. Including but not limited to: *.skp files, *.vrmesh
                    files and *.mesh.thea files.
                    <br />
                    <br />
                    There are two exceptions in which case you are allowed to sell the files
                    generated by Transmutr Artist:
                    <br />
                    - The files are created as a commission, and you sell them to the commissioner
                    only
                    <br />
                    - You sell them exclusively through the 3D Bazaar Marketplace
                    <br />
                    <br />
                    Why, you ask?
                    <br />
                    Because we feel it's fair that if you are a content creator for whom Transmutr
                    is an actual production tool, you pay extra compared to a 3D artist who uses
                    Transmutr just to add a few objects to a render, for example.
                    <br />
                    Separating these two types of licenses allows us to offer an inexpensive Artist
                    License.
                  </>
                )
              },
              {
                title: 'Which SketchUp versions are supported by Transmutr?',
                description: (
                  <>
                    Transmutr generates SketchUp 8 files. So they can be opened in all SketchUp
                    versions.
                  </>
                )
              },
              {
                title: 'What versions of Render Engines does Transmutr support?',
                description: (
                  <>
                    Transmutr creates files for:
                    <br />
                    - V-Ray 3.60.03 and above
                    <br />
                    - Thea 2.0 and above
                    <br />- Enscape 2.4 and above
                  </>
                )
              },
              {
                title: 'Software and Hardware requirements to run Transmutr',
                description: (
                  <>
                    <b>Software :</b>
                    <br />
                    Windows 10/11
                    <br />
                    MacOS 12 or above
                    <br />
                    <br />
                    <b>Recommended hardware :</b>
                    <br />
                    Quad-core 3+ Ghz processor
                    <br />
                    8+ GB RAM{' '}
                  </>
                )
              }
            ]}
          />
        </MUI.Container>
      </MUI.Box>

      {resellerDialogOpen && (
        <SearchResellerDialog
          onClose={() => setResellerDialogOpen(false)}
          notify={props.notify}
          setMaintenanceMode={props.setMaintenanceMode}
        />
      )}

      {educatorDialogOpen && (
        <EducatorDialog
          type={educatorDialogOpen}
          email='transmutr@lindale.io'
          onClose={() => setEducatorDialogOpen(undefined)}
        />
      )}
    </MUI.Box>
  );
}
