import React from 'react';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import axios from 'axios';
import { AxiosRequestConfig } from 'axios';

import * as MUI from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import { AccountRoute } from '../Account';
import { apiBaseUrl, parseJwt, getCookie, webApiCall, NotifyFunction } from '../Common/utils';

import lindaleLogoCircle from '../../media/logos/lindale_logo_circle.svg';

const useStyles = makeStyles((theme) => ({
  alertMessage: {
    marginBottom: theme.spacing(2)
  },
  form: {
    marginTop: theme.spacing(2),
    width: '100%' // Fix IE 11 issue.
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

interface Props {
  currentRoute: AccountRoute;
  jwt?: string;
  setJwt: (jwt: string | undefined) => void;
  saveRefreshToken: (refreshToken: string | undefined) => void;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export function AccountForm(props: Props) {
  const classes = useStyles();

  const [email, setEmail] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [offerResendingVerificationEmail, setOfferResendingVerificationEmail] =
    React.useState(false);
  const [waiting, setWaiting] = React.useState(false);

  let history = useHistory();
  let location = useLocation();
  let { verificationKey, resetPasswordToken }: any = useParams();
  const locationParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const submit = React.useCallback(
    (email: string, username: string, password: string, newPassword: string) => {
      setWaiting(true);
      setErrors({});
      setSuccessMessage('');
      setOfferResendingVerificationEmail(false);

      let method: 'get' | 'post' = 'get';
      let endpoint = '';
      let data = {};

      let jwtUserId = '';
      if (props.jwt) {
        jwtUserId = parseJwt(props.jwt).id;
      }

      switch (props.currentRoute) {
        case AccountRoute.LOGIN:
          method = 'post';
          endpoint = 'auth/v1/login';
          data = {
            email: email,
            password: password
          };
          break;
        case AccountRoute.REGISTER:
          method = 'post';
          endpoint = 'auth/v1/register';
          data = {
            email: email,
            username: username,
            password: password
          };
          break;
        case AccountRoute.MANAGE:
          method = 'post';
          endpoint = 'auth/v1/update/' + jwtUserId;
          data = {
            email: email,
            username: username,
            password: password,
            newPassword: newPassword,
            refreshToken: getCookie('refreshToken')
          };
          break;
        case AccountRoute.FORGOTPASSWORD:
          method = 'post';
          endpoint = 'auth/v1/requestpasswordreset';
          data = {
            email: email
          };
          break;
        case AccountRoute.RESETPASSWORD:
          method = 'post';
          endpoint = 'auth/v1/resetpassword';
          data = {
            token: resetPasswordToken,
            newPassword: newPassword
          };
          break;
        case AccountRoute.VERIFY:
          method = 'get';
          endpoint = 'auth/v1/verify/' + verificationKey;
          break;
      }

      webApiCall({
        method: method,
        endpoint: endpoint,
        jwt: props.jwt,
        data: data,
        suppressWebApiErrors: true,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response, message) => {
          switch (props.currentRoute) {
            case AccountRoute.LOGIN:
            case AccountRoute.MANAGE:
              if (response.data.data.jwt && response.data.data.refreshToken) {
                props.setJwt(response.data.data.jwt);
                props.saveRefreshToken(response.data.data.refreshToken);
                if (message) {
                  setSuccessMessage(message);
                }
                if (locationParams.return && locationParams.return !== 'bazaarextension') {
                  window.location.href = locationParams.return as string;
                } else {
                  history.push({
                    pathname: `/account/manage`,
                    search: location.search
                  });
                }

                // @ts-ignore
                if (window.tolt_referral) {
                  // @ts-ignore
                  window.tolt.signup(email);
                }
              } else {
                setErrors({
                  message: 'Missing jwt or refreshToken, please contact contact@lindale.io'
                });
              }
              break;

            case AccountRoute.REGISTER:
            case AccountRoute.FORGOTPASSWORD:
            case AccountRoute.RESETPASSWORD:
            case AccountRoute.VERIFY:
              setSuccessMessage(
                `${message}${
                  locationParams.return === 'bazaarextension'
                    ? ' You can now go back to the 3D Bazaar Extension to log in.'
                    : ''
                }`
              );
              break;
          }
        },
        errorCallback: (response, message) => {
          if (response.data.data.accountPending) {
            // Redirect to the register page, prefill the email field, and display a message
            history.push({
              pathname: `/account/register`,
              search: `?pending&emailb64=${btoa(email)}`
            });
          } else if (message || response.data.data) {
            if (message) {
              setErrors({ message: message });
            } else if (response.data.data) {
              setErrors(response.data.data);
            }
            if (response.data.data?.offerResendingVerificationEmail) {
              setOfferResendingVerificationEmail(true);
            }
          } else {
            setErrors({
              message: 'An unexpected error occured, please contact contact@lindale.io'
            });
          }
        },
        ensureCallback: () => {
          setWaiting(false);
        }
      });
    },
    [history, location.search, locationParams.return, props, resetPasswordToken, verificationKey]
  );

  const resendVerificationEmail = React.useCallback(() => {
    setWaiting(true);
    setErrors({});
    setSuccessMessage('');
    setOfferResendingVerificationEmail(false);

    webApiCall({
      method: 'post',
      endpoint: 'auth/v1/resendverification',
      data: { email: email },
      notify: props.notify,
      setMaintenanceMode: props.setMaintenanceMode,
      successCallback: (response, message) => {
        if (message) {
          setSuccessMessage(message);
        }
        setWaiting(false);
      },
      errorCallback: (response, message) => {
        if (message) {
          setErrors({ message: message });
        } else if (response.data.data) {
          setErrors(response.data.data);
        }
        setWaiting(false);
      }
    });
  }, [email]);

  React.useEffect(() => {
    setEmail(
      locationParams.pending !== undefined && locationParams.emailb64
        ? atob(locationParams.emailb64 as string)
        : ''
    );
    setUsername('');
    setPassword('');
    setNewPassword('');
    setSuccessMessage('');
    setErrors({});
    setWaiting(false);

    if (props.jwt) {
      if (
        props.currentRoute === AccountRoute.LOGIN ||
        props.currentRoute === AccountRoute.REGISTER
      ) {
        history.push({ pathname: `/account/manage`, search: location.search });
      }

      if (props.currentRoute === AccountRoute.MANAGE) {
        // Convert JWT payload to object
        setEmail(parseJwt(props.jwt).email);
        setUsername(parseJwt(props.jwt).username);
      }
    }

    if (props.currentRoute === AccountRoute.MANAGE && !props.jwt && !getCookie('refreshToken')) {
      history.push({ pathname: `/account/login`, search: location.search });
    }

    if (props.currentRoute === AccountRoute.VERIFY && verificationKey && verificationKey !== '') {
      submit(email, username, password, newPassword);
    }
  }, [
    props.jwt,
    props.currentRoute,
    verificationKey,
    locationParams.pending,
    locationParams.emailb64,
    history,
    location.search,
    submit
  ]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const formIsSubmittable = !preventFormSubmit();
    if (e.key === 'Enter' && formIsSubmittable) {
      submit(email, username, password, newPassword);
    }
  };

  const preventFormSubmit = () => {
    return (
      waiting ||
      ((props.currentRoute === AccountRoute.LOGIN ||
        props.currentRoute === AccountRoute.REGISTER ||
        props.currentRoute === AccountRoute.FORGOTPASSWORD ||
        props.currentRoute === AccountRoute.MANAGE) &&
        email === '') ||
      ((props.currentRoute === AccountRoute.LOGIN ||
        props.currentRoute === AccountRoute.REGISTER ||
        props.currentRoute === AccountRoute.MANAGE) &&
        password === '') ||
      ((props.currentRoute === AccountRoute.REGISTER ||
        props.currentRoute === AccountRoute.MANAGE) &&
        username === '') ||
      (props.currentRoute === AccountRoute.RESETPASSWORD && newPassword === '')
    );
  };

  const logout = () => {
    props.setJwt(undefined);
    props.saveRefreshToken(undefined);

    history.push({ pathname: `/account/login`, search: location.search });
  };

  let title;
  switch (props.currentRoute) {
    case AccountRoute.LOGIN:
      title = 'Log in';
      break;
    case AccountRoute.REGISTER:
      title = 'Register';
      break;
    case AccountRoute.FORGOTPASSWORD:
      title = 'Request password reset';
      break;
    case AccountRoute.RESETPASSWORD:
      title = 'Reset password';
      break;
    case AccountRoute.VERIFY:
      title = 'Verify account';
      break;
  }

  if (!props.jwt && getCookie('refreshToken')) {
    // Currently refreshing the jwt
    return (
      <MUI.Box mt={4}>
        <MUI.CircularProgress />
      </MUI.Box>
    );
  } else {
    return (
      <MUI.Container
        maxWidth='xs'
        style={{
          marginTop: props.currentRoute !== AccountRoute.MANAGE ? '64px' : undefined,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <MUI.Avatar
          style={{
            margin: '8px',
            height: '64px',
            width: '64px'
          }}
          src={lindaleLogoCircle}
        />
        {title && (
          <MUI.Typography component='h1' variant='h5'>
            {title}
          </MUI.Typography>
        )}

        <form className={classes.form} noValidate onKeyDown={onKeyDown}>
          {props.currentRoute === AccountRoute.REGISTER &&
            locationParams.pending !== undefined &&
            locationParams.emailb64 && (
              <Alert severity='info' className={classes.alertMessage}>
                A temporary account was automatically created for{' '}
                <b>{atob(locationParams.emailb64 as string)}</b> (e.g. when purchasing a license).
                <br />
                Please finish setting up your account using the form below.
              </Alert>
            )}
          {'message' in errors && (
            <Alert severity='error' className={classes.alertMessage}>
              {errors.message}
              {offerResendingVerificationEmail && (
                <div>
                  <a
                    onClick={() => resendVerificationEmail()}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    Click here to receive the verification email again
                  </a>
                </div>
              )}
            </Alert>
          )}
          {successMessage !== '' && (
            <Alert severity='success' className={classes.alertMessage}>
              {successMessage}
            </Alert>
          )}

          {(props.currentRoute === AccountRoute.VERIFY ||
            (props.currentRoute === AccountRoute.RESETPASSWORD && successMessage !== '')) && (
            <div className={classes.submitButton}>
              <MUI.Button
                variant='contained'
                color='primary'
                onClick={() => {
                  if (!waiting) {
                    history.push({
                      pathname: `/account/login`,
                      search: location.search
                    });
                  }
                }}
                disabled={waiting}
                fullWidth
              >
                Go to Log in
              </MUI.Button>
              {waiting && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          )}

          {(props.currentRoute === AccountRoute.LOGIN ||
            (props.currentRoute === AccountRoute.REGISTER && successMessage === '') ||
            props.currentRoute === AccountRoute.MANAGE ||
            props.currentRoute === AccountRoute.FORGOTPASSWORD) && (
            <MUI.TextField
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              error={'email' in errors}
              helperText={errors.email}
              disabled={waiting}
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus={
                props.currentRoute === AccountRoute.LOGIN ||
                props.currentRoute === AccountRoute.REGISTER ||
                props.currentRoute === AccountRoute.FORGOTPASSWORD
              }
            />
          )}

          {((props.currentRoute === AccountRoute.REGISTER && successMessage === '') ||
            props.currentRoute === AccountRoute.MANAGE) && (
            <MUI.TextField
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              error={'username' in errors}
              helperText={errors.username}
              disabled={waiting}
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='username'
              label='Username'
              name='username'
              autoComplete='username'
            />
          )}

          {(props.currentRoute === AccountRoute.LOGIN ||
            (props.currentRoute === AccountRoute.REGISTER && successMessage === '') ||
            props.currentRoute === AccountRoute.MANAGE) && (
            <MUI.TextField
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              error={'password' in errors}
              helperText={errors.password}
              disabled={waiting}
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label={props.currentRoute === AccountRoute.MANAGE ? 'Current password' : 'Password'}
              type='password'
              id='password'
              autoComplete='current-password'
            />
          )}

          {(props.currentRoute === AccountRoute.MANAGE ||
            (props.currentRoute === AccountRoute.RESETPASSWORD && successMessage === '')) && (
            <MUI.TextField
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              error={'newPassword' in errors}
              helperText={errors.newPassword}
              disabled={waiting}
              variant='outlined'
              margin='normal'
              required={props.currentRoute === AccountRoute.RESETPASSWORD}
              fullWidth
              name='newPassword'
              label='New password'
              type='password'
              id='newPassword'
            />
          )}

          {(props.currentRoute === AccountRoute.LOGIN ||
            (props.currentRoute === AccountRoute.REGISTER && successMessage === '') ||
            props.currentRoute === AccountRoute.MANAGE ||
            props.currentRoute === AccountRoute.FORGOTPASSWORD ||
            (props.currentRoute === AccountRoute.RESETPASSWORD && successMessage === '')) && (
            <div className={classes.submitButton}>
              <MUI.Button
                variant='contained'
                color='primary'
                fullWidth
                onClick={() => {
                  submit(email, username, password, newPassword);
                }}
                disabled={preventFormSubmit()}
              >
                {props.currentRoute === AccountRoute.LOGIN && 'Log in'}
                {props.currentRoute === AccountRoute.REGISTER && 'Register'}
                {props.currentRoute === AccountRoute.MANAGE && 'Update Account'}
                {props.currentRoute === AccountRoute.FORGOTPASSWORD && 'Request password reset'}
                {props.currentRoute === AccountRoute.RESETPASSWORD && 'Reset password'}
              </MUI.Button>
              {waiting && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          )}

          <MUI.Grid container>
            <MUI.Grid item xs>
              {props.currentRoute === AccountRoute.LOGIN && (
                <MUI.Link variant='body2' component={RouterLink} to={`/account/forgotpassword`}>
                  Forgot password?
                </MUI.Link>
              )}

              {(props.currentRoute === AccountRoute.FORGOTPASSWORD ||
                props.currentRoute === AccountRoute.RESETPASSWORD) && (
                <MUI.Link variant='body2' component={RouterLink} to={`/account/login`}>
                  Back to Login
                </MUI.Link>
              )}
            </MUI.Grid>

            <MUI.Grid item>
              {props.currentRoute === AccountRoute.LOGIN && (
                <MUI.Link variant='body2' component={RouterLink} to={`/account/register`}>
                  Don't have an account? Register
                </MUI.Link>
              )}

              {props.currentRoute === AccountRoute.REGISTER && (
                <MUI.Link variant='body2' component={RouterLink} to={`/account/login`}>
                  Already registered? Log in
                </MUI.Link>
              )}

              {props.currentRoute === AccountRoute.MANAGE && (
                <MUI.Link variant='body2' onClick={() => logout()} href=''>
                  Log out
                </MUI.Link>
              )}
            </MUI.Grid>
          </MUI.Grid>
        </form>
      </MUI.Container>
    );
  }
}
